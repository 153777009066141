/* eslint-disable react-hooks/rules-of-hooks */
import { useFrame, useLoader } from "@react-three/fiber";
import useWebSocket, { EventTypes } from "hooks/useWebSocket";
import { useEffect, useRef, useState } from "react";
import * as THREE from "three";
import { StreamSegmentConfigType } from "types";

export default function EnvironmentOuterGlobe({
  reference,
  intersectedGlobe,
  lowResImage,
  depthmapImage,
  userGettingAwayRef,
  dataPointList,
  currentDatapointIndex,
}) {
  const uThreshold = 30.0;
  const highResScale = 1.0;
  const dataPoint = dataPointList[currentDatapointIndex];
  const dataPointId = dataPoint.datapointId;
  const { socket, publishGlobeIntersectionPoint } = useWebSocket();
  const segmentImage = useRef<string>();
  const [segmentTexture, setSegmentTexture] = useState(null);

  useEffect(() => {
    if (segmentImage.current) {
      const textureLoader = new THREE.TextureLoader();
      textureLoader.load(segmentImage.current, (texture) => {
        setSegmentTexture(texture);
      });
    }
  }, [segmentImage.current]);

  useEffect(() => {
    if (socket) {
      socket.on(
        EventTypes.STREAM_SEGMENT_IMAGE,
        (data: StreamSegmentConfigType) => {
          console.log("🔳 Sync Streaming Data: ", data);
          segmentImage.current = data.imageBase64;

          const textureLoader = new THREE.TextureLoader();
          textureLoader.load(segmentImage.current, (texture) => {
            setSegmentTexture(texture);
          });
        }
      );
    }

    return () => {
      if (socket) {
        socket.emit(EventTypes.DISCONNECT);
        socket.disconnect();
      }
    };
  }, [socket]);

  const [lowResTexture, depth] = lowResImage
    ? useLoader(THREE.TextureLoader, [lowResImage, depthmapImage])
    : null;
  // const [highResTexture, depth] =
  //   highResImage && depthmapImage
  //     ? useLoader(THREE.TextureLoader, [
  //         highResImage,
  //         // "/images/output_frame_8K_depth_map.png",
  //         depthmapImage,
  //       ])
  //     : null;
  const [texturesLoaded, setTexturesLoaded] = useState(false);
  const uvCoordinateRef = useRef<{ u: number; v: number }>();

  const vertexShader = `
    varying vec3 vPosition;
    varying vec2 vUv;
    varying float vDistance;
    varying float vShowRed;
    varying vec4 vDepthMapData;

    uniform int uIntersectedFaceIndex;
    uniform vec3 uFaceA;
    uniform vec3 uFaceB;
    uniform vec3 uFaceC;
    uniform float uThreshold; // Distance threshold

    uniform sampler2D depthMap;
    uniform float displacementScale;
    uniform float minDepth;
    uniform float maxDepth;

    vec3 calculateCentroid(vec3 a, vec3 b, vec3 c) {
      return (a + b + c) / 3.0;
    }

    void main() {
      vUv = uv;
      // vUv = vec2(1.0 - uv.x, uv.y); // Flip the U coordinate
      // vUv = vec2(uv.x, 1.0 - uv.y); // Flip the V coordinate
      vPosition = position;

      vec4 depthData = texture2D(depthMap, uv);
      float r = depthData.r * 255.0;
      float g = depthData.g * 255.0;
      float b = depthData.b * 255.0;

      float vDisplacement = depthData.r * displacementScale;
      vec3 transformedPosition = position + normal * vDisplacement;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(transformedPosition, 1.0);

      if (uIntersectedFaceIndex >= 0) {
        // ⭐️ Distance calculation around point of intersection
        vec3 faceTriangleCentroid = calculateCentroid(uFaceA, uFaceB, uFaceC);
        vDistance = length(vPosition - faceTriangleCentroid);
      } else {
        vDistance = 1.0; // Default to a value greater than the threshold
      }
    }
  `;

  const fragmentShader = `
    varying vec2 vUv;
    varying vec3 vPosition;
    varying float vDistance;
    varying float vShowRed;
    varying vec4 vDepthMapData;

    uniform sampler2D uLowResTexture;
    uniform sampler2D uHighResTexture;
    uniform int uIntersectedFaceIndex;
    uniform float uThreshold;
    uniform float minDepth;
    uniform float maxDepth;
    uniform bool userGettingAway;
    uniform float highResScale;

    void main() {
      vec3 color = vec3(0.0);

      if (!userGettingAway) {
        color = texture2D(uLowResTexture, vUv).rgb;
        
        vec2 scaledUv = vUv * highResScale;
        vec4 highResColor = texture2D(uHighResTexture, scaledUv);
        
        if (highResColor.a > 0.0) {
          color = highResColor.rgb;
        }
      }

      // Render a red circle if the vertex is close to the face centroid
      float circleRadius = 2.0;
      if (vDistance < circleRadius && uIntersectedFaceIndex >= 0) {
        color = vec3(255.0, 255.0, 255.0);
      }

      gl_FragColor = vec4(color, 1.0);
    }
  `;

  const CustomShader = {
    vertexShader,
    fragmentShader,
  };

  useFrame(() => {
    if (!reference.current) return;
    if (!intersectedGlobe.current || intersectedGlobe.current.faceIndex < 0)
      return;

    // console.log('intersectedGlobe: ', intersectedGlobe.current)

    const uv = {
      u: intersectedGlobe.current.uv.width as number,
      v: intersectedGlobe.current.uv.height as number,
    };

    if (!uvCoordinateRef.current) {
      uvCoordinateRef.current = uv;

      console.log("ref state: ", JSON.stringify(uvCoordinateRef.current));
      console.log("new uv: ", JSON.stringify(uv));
    }

    if (JSON.stringify(uvCoordinateRef.current) !== JSON.stringify(uv)) {
      // publish the current datapoint
      publishGlobeIntersectionPoint(uv.u, uv.v, dataPointId);

      uvCoordinateRef.current = uv;
    }

    const geometry = reference.current.geometry;
    const index = intersectedGlobe.current.faceIndex;

    const face = geometry.index
      ? [
          geometry.index.array[index * 3],
          geometry.index.array[index * 3 + 1],
          geometry.index.array[index * 3 + 2],
        ]
      : [index * 3, index * 3 + 1, index * 3 + 2];

    const vertices = geometry.attributes.position.array;

    const faceA = new THREE.Vector3(
      vertices[face[0] * 3],
      vertices[face[0] * 3 + 1],
      vertices[face[0] * 3 + 2]
    );

    const faceB = new THREE.Vector3(
      vertices[face[1] * 3],
      vertices[face[1] * 3 + 1],
      vertices[face[1] * 3 + 2]
    );

    const faceC = new THREE.Vector3(
      vertices[face[2] * 3],
      vertices[face[2] * 3 + 1],
      vertices[face[2] * 3 + 2]
    );

    reference.current.material.uniforms.uFaceA.value = faceA;
    reference.current.material.uniforms.uFaceB.value = faceB;
    reference.current.material.uniforms.uFaceC.value = faceC;
    reference.current.material.uniforms.uIntersectedFaceIndex.value =
      intersectedGlobe.current.faceIndex;
  });

  useEffect(() => {
    if (lowResTexture && depthmapImage && depth) {
      setTexturesLoaded(true);
      lowResTexture.minFilter = THREE.NearestFilter;
      lowResTexture.generateMipmaps = false;

      depth.minFilter = THREE.NearestFilter;
      depth.generateMipmaps = false;
    }
  }, [lowResTexture, depthmapImage, depth]);

  useEffect(() => {
    if (socket) {
      socket.on(
        EventTypes.STREAM_SEGMENT_IMAGE,
        (data: StreamSegmentConfigType) => {
          console.log(
            "🔳🔳🔳🔳🔳🔳🔳 🌠🌠🌠🌠🌠🌠🌠🌠 Sync Streaming Data: ",
            data
          );
          segmentImage.current = data.imageBase64;
        }
      );
    } else {
      console.log("Socket is not initialized.");
    }

    return () => {
      if (socket) {
        socket.emit(EventTypes.DISCONNECT);
        socket.disconnect();
      }
    };
  }, [socket]);

  if (!texturesLoaded) return null;

  return (
    <mesh ref={reference} rotation={[0, Math.PI / 2, 0]}>
      <sphereGeometry args={[60, 1000, 1000]} />
      <shaderMaterial
        args={[
          {
            uniforms: {
              uLowResTexture: { value: lowResTexture || new THREE.Texture() },
              uHighResTexture: { value: segmentTexture || new THREE.Texture() },
              depthMap: { value: depth || new THREE.Texture() },
              displacementScale: { value: 20 },
              uIntersectedFaceIndex: { value: -1 },
              uFaceA: { value: new THREE.Vector3() },
              uFaceB: { value: new THREE.Vector3() },
              uFaceC: { value: new THREE.Vector3() },
              uThreshold: { value: uThreshold },
              minDepth: { value: 50.0 },
              maxDepth: { value: 140.0 },
              userGettingAway: { value: userGettingAwayRef.current },
              highResScale: { value: highResScale },
            },
            vertexShader: CustomShader.vertexShader,
            fragmentShader: CustomShader.fragmentShader,
            side: THREE.BackSide,
          },
        ]}
      />
    </mesh>
  );
}
