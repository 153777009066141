export interface DataPoint {
  datapoint: string;
  id: string;
  name: number;
  position: number[];
  regionRef: string;
  propertyRef: string;
  highresImageUrl: string | undefined;
  lowResImageUrl: string | undefined;
  depthmapImageUrl: string | undefined;
  frontImageUrl: string | undefined;
  rightImageUrl: string | undefined;
  leftImageUrl: string | undefined;
  topImageUrl: string | undefined;
  bottomImageUrl: string | undefined;
  backImageUrl: string | undefined;
}

export interface Region {
  id: string;
  label: string;
  position: [number, number, number];
  propertyRef: string;
  regionCoordinates: [number, number][];
  datapoints: DataPoint[];
}

export interface Property {
  id: string;
  title: string;
  regions: Region[];
}

export enum StreamingDataType {
  HIGH_RESOLUTION = 'high_resolution',
  LOW_RESOLUTION = 'low_resolution',
  DEPTH_MAP_RESOLUTION = 'depth_map_resolution',
}

export type StreamingDataConfigType = {
  datapointId: string;
  imageBase64: string;
  type: StreamingDataType;
};

export type StreamSegmentConfigType = {
  id: string;
  columnNumber: number;
  maxU: number;
  maxV: number;
  minU: number;
  minV: number;
  name: string;
  pngImageUrl: string;
  rowNumber: number;
  webpImageUrl: string;
  propertyId?: string;
  regionId?: string | null;
  datapointId?: string | null;
  createdAt: Date;
  updatedAt: Date;
  imageBase64: string;
};
