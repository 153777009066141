import { io } from "socket.io-client";

const socket = io(process.env.REACT_APP_SOCKET_IO_URL, {
  transports: ["websocket"],
  reconnectionAttempts: 5,
  reconnectionDelay: 1000,
});

export enum EventTypes {
  USER_CONNECTED = "user_connected",
  PLAYER_COORDINATES_CHANGED = "player_coordinates_changed",
  INITIATE_DATA_STREAMING = "initiate_data_streaming",
  SYNC_STREAMING_DATA = "sync_streaming_data",
  DISCONNECT = "DISCONNECT",
  INTERSECTION_POINT = 'intersection_point',
  STREAM_SEGMENT_IMAGE = 'stream_segment_image',
}

const useWebSocket = () => {
  const handleUserConnectedEmitEvent = (coordinates: any) => {
    socket.emit(EventTypes.USER_CONNECTED, coordinates);
  };

  const handleCoordinatesEmitEvent = (coordinates: any) => {
    socket.emit(EventTypes.PLAYER_COORDINATES_CHANGED, coordinates);
  };

  const emitInitiateDataStreamingProcessEvent = (propertyId: string) => {
    socket.emit(EventTypes.INITIATE_DATA_STREAMING, propertyId);
  };

  const publishGlobeIntersectionPoint = (u: number, v: number, dataPointId: string) => {
    socket.emit(EventTypes.INTERSECTION_POINT, { u, v, dataPointId });
  }

  return {
    socket,
    handleUserConnectedEmitEvent,
    handleCoordinatesEmitEvent,
    emitInitiateDataStreamingProcessEvent,
    publishGlobeIntersectionPoint
  };
};

export default useWebSocket;
