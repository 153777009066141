import React, { useRef } from 'react';
import { Canvas, useLoader } from '@react-three/fiber';
import { ShaderMaterial, TextureLoader } from 'three';
import { OrbitControls } from '@react-three/drei';

function SphereWithShader() {
  const sphereRef = useRef();
  
  // Load the texture
  const texture = useLoader(TextureLoader, '/images/office/IMG01.jpg');

  const vertexShader = `
    varying vec3 vPosition;
    varying vec2 vUv;
    void main() {
      vPosition = position;
      vUv = uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
  `;

  const fragmentShader = `
    varying vec3 vPosition;
    varying vec2 vUv;
    uniform sampler2D uTexture;
    
    void main() {
      vec3 color = vec3(0.0, 0.0, 1.0); // Base color: blue
      if(vPosition.x > 0.5) {
        color = texture2D(uTexture, vUv).rgb; // Apply texture at the top
      }
      gl_FragColor = vec4(color, 1.0);
    }
  `;

  const shaderMaterial = new ShaderMaterial({
    vertexShader,
    fragmentShader,
    uniforms: {
      uTexture: { value: texture },
    },
  });

  return (
    <mesh ref={sphereRef} material={shaderMaterial}>
      <sphereGeometry args={[1, 64, 64]} />
    </mesh>
  );
}

function App() {
  return (
    <Canvas>
      <ambientLight />
      <pointLight position={[10, 10, 10]} />
      <SphereWithShader />
      <OrbitControls />
    </Canvas>
  );
}

export default App;
